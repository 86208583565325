<template>
  <div class="mb-5">
    <Swiper :sliderImages="swiperData"/>
    <div id="our-service" class="mt-4 mb-5 pt-5">
      <h1>{{$t('TITLE.OUR_SERVICES')}}</h1>
    </div>

    <!-- Service Section Starts here -->
    <div class="d-flex px-2 justify-content-between company-services">
      <div class="service-list">
        <ul>
          <li 
            v-for="(service,ind) of servicesData"
            :key="ind"
            class="d-flex align-items-center mb-5">
            <div class="arrow-icon">
              <img :src="rightArrowIcon" />
            </div>
            <div class="service ml-4">
              <div class="text-left header">{{$t(service.title)}}</div>
              <div class="text-left description">{{$t(service.description)}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="image-banner">
        <img :src="ourServiceImage" alt="Our Service"/>
      </div>
    </div> 
    <!-- Service Section End here -->     

    <!-- Why Us Section Starts here -->
    <div class="why-us-section pb-5">
      <div id="why-us" class="mt-4 mb-5 pt-5">
        <h1>{{$t('TITLE.WHY_US')}}</h1>
      </div>
      <div class="card-container">
        <div class="row">
          <div 
            v-for="(purpose,ind) of purposeData"
            :key="ind"
            class="col-md-4 my-4">
            <Card
              :icon="purpose.icon"
              :title="purpose.title"
              :description="purpose.description"
              />
          </div>
        </div>
      </div>
    </div>
    <!-- Why Us Section End here --> 

    <!-- Contact Us Section Starts here -->
    <div id="contact-us" class="mt-4 mb-5">
        <h1>{{$t('TITLE.CONTACT_US')}}</h1>
      </div>
      <div class="d-flex px-5 justify-content-between company-services">
          <div class="google-map-container">
            <div style="width: 100%; height:100%"><iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=L1-43,%20Jalan%20Sungai%20Kapar%20Indah%202/KU7%20Kawasan%20Industri%20Hi-Tech,%20Sungai%20Kapar%20Indah,%2042200%20Klang,%20Selangor+(el%20cold%20chain%20logistics)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
          </div>
          <div class="company-description text-left">
            <h3>{{$t('COMPANY.ESTABLISHED_YEAR', {'year':2019})}}</h3>
            <div class="mt-5">
              <p>{{$t('COMPANY.INFO.EL_COLD_CHAIN_LOGISTIC')}}</p>
              <p>{{$t("ACTION.EMAIL")}} 📧  &nbsp&nbsp : <a href = "mailto: Bent.Koh@edaranlivall.com">Bent.Koh@edaranlivall.com</a></p>
              <div class="d-flex">
                <p>{{$t("ACTION.ADDRESS")}} &nbsp&nbsp : &nbsp</p>
                <p>L1-43, Jalan Sungai Kapar Indah<br/>
                   2/KU7 Kawasan Industri Hi-Tech,<br/>
                   Sungai Kapar Indah, 42200 Klang, Selangor</p>
              </div>
              <div class="d-flex"> 
                <p class="contact-numbers-label">{{$t('ACTION.PHONE_NO')}} : &nbsp</p>
                <p class="contact-numbers">
                  <span><a href="tel:+60125503808">012-550 3808</a></span>
                  <span><a href="tel:+0332929178">03-32929178</a></span>
                </p>
              </div>
              <Icons
                :iconContainerSize="'50%'"
                :iconSize="'25%'"
                :hasWhatsapp="true"
                :whatsappLink="'https://api.whatsapp.com/send?phone=60125503808'"/>
            </div>
          </div>
      </div>
    <!-- Contact Us Section End here -->     
  </div>
</template>

<script>
import ourServiceImage from '@/assets/images/elColdChain/our_service.jpg'
import rightArrowIcon from '@/assets/icons/right_arrow.png'
import swiperImageData from '@/data/swiperImage'
import servicesData from '@/data/details'
import Swiper from '@/components/Swiper'
import Card from '@/components/Card'
import Icons from '@/components/Icons'
export default {
  name:"El_Cold_Chain_Logistic",
  components:{
    Swiper,
    Card,
    Icons
  },
  data(){
    return{
      swiperData: {...swiperImageData.elColdChain},
      servicesData:{...servicesData.elColdChain.services},
      purposeData:{...servicesData.elColdChain.purpose},
      ourServiceImage: ourServiceImage,
      rightArrowIcon: rightArrowIcon,
    }
  }
}
</script>

<style lang="scss" scoped>
.company-services{
  .service-list{
    width:60%;

    li{
      transition: .2s all;
      cursor: pointer;

      &:hover{
        transform: translateX(25px);
      }
    }

    .arrow-icon{
        max-width: 20%;
      img{
        width: 100%;
      }
    }

    .service{
      width: 70%;

      .header{
        font-weight: bold;
        font-size: 1.1rem;
      }

      .description{
        font-size: 0.9rem;
      }
    }

  }

  .image-banner{    
    width:40%;
    margin-right: 15px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.why-us-section{
  background: #f9f4f4;

  .card-container{
    max-width: 80%;
    margin: 0 auto;
    padding: 0 1rem;
  }

}

.google-map-container{
  width: 35%;
  height:45vh;
  position: relative;

  iframe{
    position: absolute;
    left:0;
  }
}

.company-description{
  width:60%
}

.contact-numbers{
  span{
    margin-right: 10px;
  }
}


@media screen and (max-width: 600px){
  .company-services{
    flex-direction: column-reverse;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    .image-banner, .company-description, .google-map-container{
      width: 100%;
    }

    .service-list{
      width:100%;

      ul{
        padding: 0;
      }
    }
  }

  .contact-numbers-label{
    width: 25%;
  }

  .contact-numbers{
    width: 75%;
    display: flex;
    flex-wrap: wrap;

    span{
      width: 45%;
    }
  }

  .why-us-section .card-container{
    max-width: 95% ;
  }

}
</style>