<template>
  <div class="mb-5">
      <Swiper :sliderImages="swiperData"/>
      <div id="about" class="mt-4 mb-5">
        <h1>{{$t('TITLE.ABOUT_US')}}</h1>
      </div>
      <div class="d-flex px-5 justify-content-between company-services">
          <div class="company-description text-left">
            <h3>{{$t('COMPANY.ESTABLISHED_YEAR', {'year':2021})}}</h3>
            <p v-html="$t('COMPANY.INFO.DRAGON_GLOBAL')"></p>
            <p>{{$t('COMPANY.HISTORY.DRAGON_GLOBAL.H_1')}}</p>
            <p>{{$t('COMPANY.HISTORY.DRAGON_GLOBAL.H_2')}}</p>
            <div class="mt-5">
              <h4>{{$t("ACTION.CONTACT_US")}}</h4>
              <p>{{$t("ACTION.EMAIL")}} 📧  &nbsp&nbsp : <a href = "mailto: mrjie90@hotmail.com">mrjie90@hotmail.com</a></p>
              <div class="d-flex"> 
                <p>{{$t('ACTION.PHONE_NO')}} : &nbsp</p>
                <p><a href="tel:+60192188344">019-218 8344</a></p>
              </div>
              <Icons
                :iconContainerSize="'50%'"
                :iconSize="'25%'"
                :hasWhatsapp="true"                
                :whatsappLink="'https://api.whatsapp.com/send?phone=60192188344'"/>
            </div>
          </div>
      </div>


  </div>
</template>

<script>
import swiperImageData from '@/data/swiperImage'
import Swiper from '@/components/Swiper'
import Icons from '@/components/Icons'
export default {
  name:"Dragon_Global",
  components:{
    Swiper,
    Icons
  },
    data(){
    return{
      swiperData: {...swiperImageData.dragonGlobal}
    }
  }
}
</script>

<style lang="scss" scoped>

.company-description{
  width:60%
}

@media screen and (max-width:600px){
    .company-services{
        flex-direction: column-reverse;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .company-description, .google-map-container{
      width: 100%;
    }
}


</style>