<template>
    <div class="card">
        <div class="icon-container">
            <img :src="icon" alt="icons"/>
        </div>
        <div class="title my-3">
            <h4>{{$t(title)}}</h4>
        </div>
        <div class="description">
            <p>{{$t(description)}}</p>
        </div>        
    </div>
</template>
<script>
export default {
    name:"Card",
    props:{
        icon:{
            type:String,
            default:""
        },
        title:{
            type:String,
            default:""
        },
        description:{
            type:String,
            default:""
        }
    },
}
</script>
<style lang="scss" scoped>
.card{
    padding: 25px;
    border-radius:10px;
    height: 100%;

    .icon-container{
        width: 40px;
        margin: 0 auto;
        img{
            width: 100%;
        }
    }
}
</style>
